<template>
  <Teleport to="#teleports">
    <Transition
      enter-from-class="opacity-0"
      enter-active-class="transition duration-100"
      leave-to-class="opacity-0"
      leave-active-class="transition duration-100"
    >
      <div
        v-if="modelValue"
        ref="root"
        :class="className"
        class="fixed inset-0 bg-gray-900/70 backdrop-blur-sm z-[9999] p-12 flex flex-col justify-center"
        @click="bodyclick"
      >
        <button
          v-if="closable"
          type="button"
          class="absolute right-4 top-4"
          @click="handleModalClose"
        >
          <svg
            class="w-6 h-6 box-content p-2 rounded-full fill-white hover:bg-white/10 transition"
            viewBox="0 0 24 24"
          >
            <path
              d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
            />
          </svg>
        </button>
        <slot />
      </div>
    </Transition>
  </Teleport>
</template>
<script setup>
const props = defineProps({
  class: {
    type: String,
    default: "",
  },

  modelValue: {
    type: [Boolean, null, undefined],
    required: true,
    default: false,
  },

  closable: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(["update:modelValue", "close"]);

const root = ref(null);

const className = computed(() => {
  return props.class;
});

function handleModalClose() {
  emit("update:modelValue", false);
  emit("close", false);
}

function bodyclick(event) {
  if (event.target === root.value) {
    handleModalClose();
  }
}
</script>
